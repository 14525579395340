@use "viz-common/src/sass/primeng/_variables.sass" as v
@import "~primeicons/primeicons.css"
@import "~primeng/resources/primeng.min.css"

@mixin corner-icon($top-or-bottom, $left-or-right, $top-or-bottom-value, $left-or-right-value)
  position: absolute
  #{$top-or-bottom}: #{$top-or-bottom-value}px
  #{$left-or-right}: #{$left-or-right-value}px

@mixin shadow()
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

@mixin hide()
  visibility: hidden

@mixin show()
  visibility: visible

@mixin icon-button
  width: 40px
  height: 40px
  background: v.$primary-color
  border: 1px solid v.$primary-color
  border-radius: v.$border-radius-primary
  cursor: pointer
  &:hover
    background: v.$primary-color-selected
    border: 1px solid v.$primary-color-selected

  &:focus
    box-shadow: none
  span.p-button-label
    width: 0


.activated
  background: v.$primary-color-active !important
  border: 1px solid v.$primary-color-active !important
  color: v.$text-color-primary !important

.secondary-text
  font-size: 14px
  line-height: 19px

:not(.light-theme *).secondary-text
  color: rgba(255, 255, 255, 0.6)

.dropbox
  width: 100%

.unselectable
  -webkit-touch-callout: none
  // iOS Safari
  -webkit-user-select: none
  // Safari
  -khtml-user-select: none
  // Konqueror HTML
  -moz-user-select: none
  // Old versions of Firefox
  -ms-user-select: none
  // Internet Explorer/Edge
  user-select: none
// Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox

@mixin scrollbar-primary($width, $color)
  :not(.light-theme *)
    &::-webkit-scrollbar
      width: #{$width}
      height: #{$width}

    /* Track */
    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px v.$primary-color-selected
      -webkit-border-radius: 1px
      border-radius: 1px

    /* Handle */
    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 10px
      border-radius: 10px
      background: #{$color}
      -webkit-box-shadow: inset 0 0 6px v.$primary-color-selected
