@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer,
.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-thead > tr > th,
.p-paginator
  color: rgba(255, 255, 255, 0.87)
  background: v.$background-color-primary
  border-color: rgba(255, 255, 255, 0.4)
.p-datatable .p-datatable-tbody > tr > td
  border-color: rgba(255, 255, 255, 0.4)
.p-datatable .p-datatable-tbody > tr:focus
  outline: none
.p-datatable .p-sortable-column:not(.p-highlight),
.p-datatable .p-sortable-column.p-highlight
  color: v.$text-color-primary
  background: v.$background-color-primary
.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight:hover
  color: v.$text-color-primary
  background: v.$primary-color-selected

.p-datatable .p-sortable-column
  &:focus
    box-shadow: v.$box-shadow-active
  &.p-highlight
    .p-sortable-column-icon
      color: v.$primary-color-active
    &:hover .p-sortable-column-icon
      color: v.$primary-color-active

.p-datatable-tbody > tr > td
  border-color: rgba(255, 255, 255, 0.4)

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight
  color: v.$text-color-primary
  background: v.$primary-color-selected
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover
  color: v.$text-color-primary
  background: v.$primary-color-selected
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover
  color: v.$text-color-primary
  background: v.$primary-color-selected

.p-column-filter-menu-button
  &:hover
    color: v.$text-color-primary
    background: v.$primary-color-selected
  &.p-column-filter-menu-button-open,
  &.p-column-filter-menu-button-open:hover
    color: v.$text-color-primary
    background: v.$primary-color-active
  &:focus
    box-shadow: 0 0 0 0.2rem rgba(255, 138, 0, 0.2)
  &.p-column-filter-menu-button-active,
  &.p-column-filter-menu-button-active:hover
    color: v.$text-color-primary
    background: v.$primary-color-active
.p-column-filter-clear-button:hover
  color: v.$text-color-primary
  background: v.$primary-color-selected

.p-link:focus
  box-shadow: 0 0 0 0.2rem v.$primary-color-active

.p-column-filter-overlay
  color: v.$text-color-primary
  background: v.$background-color-primary
  z-index: 20000 !important

  .p-column-filter-row-items
    .p-column-filter-row-item.p-highlight
      color: v.$text-color-primary
      background: v.$primary-color-active

    .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover
      color: v.$text-color-primary
      background: v.$primary-color-selected

    .p-column-filter-row-item
      color: v.$text-color-primary

.p-column-filter-overlay-menu
  .p-column-filter-operator
    color: v.$text-color-primary
    background: v.$background-color-primary

  .p-dropdown,
  .p-inputtext
    color: v.$text-color-primary
    background: v.$background-color-primary
    &:hover
      border-color: v.$border-color-active
    &.p-focus
      box-shadow: 0 0 0 0.2rem rgba(255, 138, 0, 0.2)
      border-color: v.$border-color-active
  .p-inputtext
    &:focus
      box-shadow: 0 0 0 0.2rem rgba(255, 138, 0, 0.2)
      border-color: v.$border-color-active

  .p-dropdown-items-wrapper
    background: v.$background-color-primary

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item
    color: v.$text-color-primary
    background: v.$background-color-primary
    &.p-highlight,
    &:not(.p-highlight):not(.p-disabled):hover
      color: v.$text-color-primary
      background: v.$primary-color-selected

  .p-button.p-button-text,
  .p-button.p-button-outlined
    color: v.$text-color-primary
  .p-button:enabled
    color: v.$text-color-primary
    background: v.$background-color-primary
    border-color: v.$border-color-primary
    &:hover
      color: v.$primary-color-active
      border-color: v.$primary-color-active
    &:focus
      box-shadow: 0 0 0 0.2rem rgba(255, 138, 0, 0.2)
      border-color: v.$border-color-active
