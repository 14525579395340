@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-accordion
  .p-accordion-header
    .p-accordion-header-link
      background: transparent
      color: rgba(255, 255, 255, 0.87)
      border: none

    &:not(.p-disabled).p-highlight .p-accordion-header-link
      border: none
      background: transparent
      color: rgba(255, 255, 255, 0.87)

    &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    &:not(.p-disabled).p-highlight:hover .p-accordion-header-link
      border: none
      background: v.$primary-color-selected
      color: v.$primary-color-active


    &:not(.p-disabled) .p-accordion-header-link:focus
      box-shadow: none

  .p-accordion-content
    padding: 0
    border: none
    background: transparent
