@use "viz-common/src/sass/primeng/_variables.sass" as v
@use "viz-common"
@use "../src/shared/sass/primeng/p-listbox"
@use "../src/shared/sass/primeng/p-slider"
@use "../src/shared/sass/primeng/p-accordion"
@use "../src/shared/sass/primeng/p-dropdown"
@use "../src/shared/sass/primeng/p-dataview"
@use "../src/shared/sass/primeng/p-datatable"
@use "../src/shared/sass/primeng/p-inputSwitch"

@import "app/core-styles"
@include scrollbar-primary('5px', v.$scrollbar-color)

@font-face
  font-family: "Segoe UI"
  src: url("assets/font/Segoe UI.woff")

body
  margin: 0
  padding: 0
  font-family: Segoe UI, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, -apple-system, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important

ul
  list-style-type: none
  margin: 0
  padding: 0

.p-divider.p-divider-horizontal
  margin: 0

.dividerSidebar
  &:before
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important
    z-index: 1

app-print-page
  display: none

@media print
  app-root > *
    display: none
  app-print-page
    display: block

.p-tabview .p-tabview-nav
  justify-content: center
