@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-slider .p-slider-range
  background: v.$primary-color-active

.p-slider:not(.p-disabled) .p-slider-handle
  border: 2px solid v.$border-color-active
  &:hover
    border: 1px solid v.$border-color-active
    background: v.$primary-color-active

  &:focus
    box-shadow: 0 0 0 0.2rem v.$border-color-active
