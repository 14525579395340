@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div
  border-color: v.$border-color-primary

.p-dataview .p-dataview-emptymessage
  background: v.$background-color-primary

.p-dataview .p-dataview-content
  background: v.$background-color-primary
