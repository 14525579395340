@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-listbox
  border: none
:not(.light-theme).p-listbox
  background: v.$background-color-primary

.p-listbox .p-listbox-list .p-listbox-item
  padding: 0 !important

.p-listbox .p-listbox-header
  padding: 0.5rem 1rem
  background: transparent
.p-listbox .p-listbox-header .p-listbox-filter
  background: v.$background-color-primary
  color: v.$text-color-primary
  &:hover
    border-color: v.$border-color-active
  &:focus
    border-color: v.$border-color-active
    box-shadow: v.$box-shadow-active

.p-listbox .p-listbox-list .p-listbox-item-group
  background: v.$background-color-primary
  color: v.$text-color-primary

.p-listbox.p-focus
  box-shadow: none


.list-item
  width: 100%
  color: v.$text-color-primary
  background: v.$background-color-primary
  &:hover
    background: v.$primary-color-selected
  &:hover .layer-title-text
    color: v.$primary-color-active
  &:hover .layer-actions > *
    opacity: 1 !important
  &:hover .eye-icon
    visibility: visible !important

.list-item-inactive
  color: v.$text-color-primary
  background: v.$background-color-primary
  .eye-icon
    visibility: visible !important
.layer-title:hover
  background: v.$primary-color-selected !important

//.p-listbox-list-wrapper
//  overflow: initial !important

.p-listbox-item
  justify-content: space-around !important
  overflow: initial !important

