@use "viz-common/src/sass/primeng/_variables.sass" as v

.p-dropdown
  width: 100%
  background: v.$background-color-primary
  border: 1px solid rgba(255, 255, 255, 0.2)
  &:not(.p-disabled):hover
    border-color: v.$border-color-active
  &:not(.p-disabled).p-focus
    box-shadow: 0 0 0 0.2rem rgba(255, 138, 0, 0.2)
    border-color: v.$border-color-active

.p-dropdown-panel
  color: v.$text-color-primary
  background: v.$background-color-primary !important

  .p-dropdown-items
    .p-dropdown-item
      color: v.$text-color-primary !important

      &:hover
        background: v.$primary-color-selected !important

      &.p-highlight
        color: v.$text-color-primary !important
        background: v.$primary-color-selected !important
    .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus
      background: v.$primary-color-selected !important

.language-switcher
  .p-dropdown
    border-radius: 0
    border: none
    &:hover
      background: v.$primary-color-selected
    &:not(.p-disabled).p-focus
      box-shadow: none
      background: none

  .p-dropdown-trigger
    display: none

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus
    color: v.$text-color-primary !important
    background: none
